<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="
        this.type === 'view'
          ? $t('cip.plat.sync.user.title.viewHeadTitle')
          : this.type === 'add'
          ? $t('cip.plat.sync.user.title.addHeadTitle')
          : $t('cip.plat.sync.user.title.editHeadTitle')
      "
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <form-layout
      ref="formLayout"
      :column="formColumn"
      :dataForm="dataForm"
    ></form-layout>
    <div style="margin-top: 12px">
      <el-table
        :data="dataForm.sysRefs"
        size="medium"
        height="calc(100vh - 624px)"
        style="width: 100%"
      >
        <el-table-column type="index" label="序号" align="center" width="80">
        </el-table-column>
        <el-table-column prop="orgName" label="公司" align="center">
        </el-table-column>
        <el-table-column prop="deptName" label="部门" align="center">
        </el-table-column>
        <el-table-column prop="positionName" label="岗位" align="center">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { PATTERN_EMAIL, PATTERN_ID_CARD, validatenull } from "@/util/validate";
import { getDetail, getList, submit } from "@/api/sync/user";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";

export default {
  components: {
    FormLayout,
    HeadLayout,
    GridLayout,
  },
  data() {
    return {
      type: "view",
      dataForm: {},
      loading: true,
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      option: {
        index: true,
        indexLabel: this.$t("cip.cmn.title.SerialNumber"),
        menuWidth: 120,
        linklabel: "title",
        column: [
          {
            label: "公司名称",
            prop: "orgName",
            align: "left",
            overHidden: true,
            width: 240,
          },
          {
            label: "部门名称",
            prop: "deptName",
            align: "left",
            overHidden: true,
            width: 240,
          },
          {
            label: "岗位名称",
            prop: "postName",
            align: "left",
            overHidden: true,
          },
        ],
      },
      data: [],
    };
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.type)) {
        result.push({
          label: this.$t("cip.cmn.btn.saveBtn"),
          emit: "head-save",
          btnOptType: "save",
        });
        result.push({
          label: this.$t("cip.cmn.btn.saveBackBtn"),
          emit: "head-save-cancel",
        });
      }
      result.push({
        label: this.$t("cip.cmn.btn.celBtn"),
        emit: "head-cancel",
      });
      return result;
    },
    formColumn() {
      return [
        // {
        //   label: this.$t('cip.plat.sync.user.field.code'),
        //   prop: "code",
        //   span: 8,
        //   maxlength: 50,
        //   showWordLimit: true,
        //   disabled: !['edit', 'add'].includes(this.type),
        //   rules: [
        //     {
        //       required: true,
        //       message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sync.user.field.code"),
        //       trigger: "blur"
        //     }
        //   ],
        // },
        {
          label: this.$t("cip.plat.sync.user.field.name"),
          prop: "name",
          span: 8,
          maxlength: 200,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
          rules: [
            {
              required: true,
              message:
                this.$t("cip.cmn.rule.inputWarning") +
                this.$t("cip.plat.sync.user.field.name"),
              trigger: "blur",
            },
          ],
        },
        {
          label: this.$t("cip.plat.sync.user.field.account"),
          prop: "account",
          span: 8,
          maxlength: 50,
          labelWidth: 120,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
          rules: [
            {
              required: true,
              message:
                this.$t("cip.cmn.rule.inputWarning") +
                this.$t("cip.plat.sync.user.field.account"),
              trigger: "blur",
            },
            // {
            //   pattern: /^[a-zA-z1-9]*$/,
            //   message: this.$t("cip.plat.sys.user.msg.onlyEnglish"),
            // },
          ],
        },
        {
          label: this.$t("cip.plat.sync.user.field.nation"),
          prop: "nation",
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: this.$t("cip.plat.sync.user.field.nativePlace"),
          prop: "nativePlace",
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: this.$t("cip.plat.sync.user.field.entryTime"),
          prop: "entryTime",
          type: "date",
          span: 8,
          labelWidth: 120,
          valueFormat: "yyyy-MM-dd",
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: this.$t("cip.plat.sync.user.field.phone"),
          prop: "phone",
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
          rules: [
            {
              required: false,
              validator: this.validatePhone,
              trigger: "blur",
            },
          ],
        },
        {
          label: this.$t("cip.plat.sync.user.field.email"),
          prop: "email",
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
          rules: [
            {
              required: false,
              validator: this.validateEmail,
              trigger: "blur",
            },
          ],
        },
        {
          label: this.$t("cip.plat.sync.user.field.politics"),
          prop: "politics",
          type: "select",
          labelWidth: 120,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=politics",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
        {
          label: this.$t("cip.plat.sync.user.field.idNum"),
          prop: "idNum",
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
          rules: [
            {
              required: false,
              validator: this.validateIdCard,
              trigger: "blur",
            },
          ],
        },
        {
          label: this.$t("cip.plat.sync.user.field.passportNo"),
          prop: "passportNo",
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
          rules: [
            {
              required: false,
              validator: this.validatePassportNo,
              trigger: "blur",
            },
          ],
        },
        {
          label: this.$t("cip.plat.sync.user.field.birthday"),
          prop: "birthday",
          type: "date",
          span: 8,
          labelWidth: 120,
          valueFormat: "yyyy-MM-dd",
          disabled: !["add", "edit"].includes(this.type),
          "picker-options": {
            disabledDate: (time) => {
              return time.getTime() > Date.now();
            },
          },
        },
        {
          label: this.$t("cip.plat.sync.user.field.officePhone"),
          prop: "officePhone",
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
          rules: [
            {
              required: false,
              validator: this.validateOfficePhone,
              trigger: "blur",
            },
          ],
        },
        {
          label: this.$t("cip.plat.sync.user.field.idcardAddr"),
          prop: "idcardAddr",
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: this.$t("cip.plat.sync.user.field.highLevel"),
          prop: "highLevel",
          span: 8,
          type: "select",
          labelWidth: 120,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=high_level",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
        },
        {
          label: this.$t("cip.plat.sync.user.field.maritalStatus"),
          prop: "maritalStatus",
          span: 8,
          type: "select",
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=marital_status",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: this.$t("cip.plat.sync.user.field.contractStart"),
          prop: "contractStart",
          type: "date",
          span: 8,
          valueFormat: "yyyy-MM-dd",
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: this.$t("cip.plat.sync.user.field.disType"),
          prop: "disType",
          type: "select",
          labelWidth: 120,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=dis_type",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          rules: [
            {
              required: true,
              message:
                this.$t("cip.cmn.rule.selectWarning") +
                this.$t("cip.plat.sync.user.field.disType"),
              trigger: "blur",
            },
          ],
        },
        {
          label: this.$t("cip.plat.sync.user.field.rank"),
          prop: "rank",
          span: 8,
          type: "number",
          minRows: -100000000,
          maxRows: 1000000000,
          disabled: !["add", "edit"].includes(this.type),
          rules: [
            {
              required: true,
              message:
                this.$t("cip.cmn.rule.inputWarning") +
                this.$t("cip.plat.sync.user.field.rank"),
              trigger: "blur",
            },
          ],
        },
        {
          label: this.$t("cip.plat.sync.user.field.urgentName"),
          prop: "urgentName",
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: this.$t("cip.plat.sync.user.field.urgentPhone"),
          prop: "urgentPhone",
          span: 8,
          maxlength: 50,
          labelWidth: 120,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
          rules: [
            {
              required: false,
              validator: this.validatePhone1,
              trigger: "blur",
            },
          ],
        },
        {
          label: this.$t("cip.plat.sync.user.field.sex"),
          prop: "sex",
          span: 8,
          type: "switch",
          value: 0,
          dataType: "number",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=project_sex_type",
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: this.$t("cip.plat.sync.user.field.status"),
          prop: "status",
          // type: 'switch',
          type: "select",
          // value: 1,
          dataType: "number",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
        },
        {
          label: this.$t("cip.plat.sync.user.field.insured"),
          prop: "insured",
          span: 8,
          type: "switch",
          value: 1,
          labelWidth: 120,
          dataType: "number",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yes_no_type",
          disabled: !["add", "edit"].includes(this.type),
        },
      ];
    },
  },
  created() {
    let { id, type } = this.$route.query;
    this.type = type;
    if (this.type !== "add") {
      this.dataForm.id = id;
      this.initData();
    }
  },
  mounted() {},
  methods: {
    initData() {
      getDetail(this.dataForm.id).then((res) => {
        console.log("488", res);
        const { data } = res.data;
        this.dataForm = data;
      });
    },
    headSave(cancel = false) {
      this.dataForm = this.$refs.formLayout.form.form;
      if (this.dataForm.birthday !== "" && this.dataForm.entryTime !== "") {
        if (this.dataForm.birthday > this.dataForm.entryTime) {
          this.$message({
            message: "入职时间不能早于出生日期",
            type: "warning",
          });
          return;
        }
      }
      if (
        this.dataForm.contractStart !== "" &&
        this.dataForm.entryTime !== ""
      ) {
        if (this.dataForm.contractStart < this.dataForm.entryTime) {
          this.$message({
            message: "入职时间不能晚于工龄起算日",
            type: "warning",
          });
          return;
        }
      }
      if (this.dataForm.birthday !== "" && this.dataForm.contractStart !== "") {
        if (this.dataForm.birthday > this.dataForm.contractStart) {
          this.$message({
            message: "工龄起算日不能早于出生日期",
            type: "warning",
          });
          return;
        }
      }
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          submit(this.dataForm)
            .then((res) => {
              const { msg, data } = res.data;
              if (res.data.code === 200) {
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.headCancel();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data);
                this.type = "edit";
                this.initData();
              }
            })
            .finally(() => {
              this.$loading().close();
              this.$refs.formLayout.$refs.form.allDisabled = false;
            });
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 手机号校验
    validatePhone(rule, value, callback) {
      if (value !== "") {
        let isPhone = /^1[3-9]\d{9}$/;
        if (value.length === 11) {
          if (isPhone.test(value)) {
            callback();
          } else {
            callback(new Error("手机号码格式不正确"));
          }
        } else {
          callback(new Error("请输入手机号码长度为11位数字"));
        }
      } else {
        callback();
      }
    },
    // 手机号校验
    validatePhone1(rule, value, callback) {
      if (value !== "") {
        let isPhone = /^1[3-9]\d{9}$/;
        if (value.length === 11) {
          if (isPhone.test(value)) {
            callback();
          } else {
            callback(new Error("紧急联系人电话格式不正确"));
          }
        } else {
          callback(new Error("请输入紧急联系人电话长度为11位数字"));
        }
      } else {
        callback();
      }
    },
    // 邮箱校验
    validateEmail(rule, value, callback) {
      if (value !== "") {
        let valid = PATTERN_EMAIL.test(value);
        if (valid) {
          callback();
        } else {
          callback(new Error("请填写正确的邮箱"));
        }
      } else {
        callback();
      }
    },
    // 护照号校验
    validatePassportNo(rule, value, callback) {
      if (value !== "") {
        let isPassportNo = /^[Ee][1-9]\d{7}$|^[EG]\d{8}$/;
        if (isPassportNo.test(value)) {
          callback();
        } else {
          callback(new Error("护照号格式不正确"));
        }
      } else {
        callback();
      }
    },
    // 身份证校验
    validateIdCard(rule, value, callback) {
      if (value !== "") {
        let valid = PATTERN_ID_CARD.test(value);
        if (valid) {
          callback();
        } else {
          callback(new Error("请填写正确的身份证号码"));
        }
      } else {
        callback();
      }
    },
    // 办公电话校验
    validateOfficePhone(rule, value, callback) {
      if (value !== "") {
        let isOfficePhone = /^((\d{3,4})|(\d{7,8}))(-(\d{1,5}))?$/;
        if (isOfficePhone.test(value)) {
          callback();
        } else {
          callback(new Error("办公电话格式不正确"));
        }
      } else {
        callback();
      }
    },
  },
};
</script>

<style scoped>
</style>
